.color {
  color: red;
}
.headerRoot {
  background: white !important;
  flex-grow: 1 !important;
  margin-top: -2px !important;
  box-shadow: none !important;
  color: #3132e9 !important;
  font-family: lato !important;
  margin-bottom: 5%;
}
.navIconbox {
  display: none;
  margin: 0 20px 0 12px;
}
.menuButton {
  font-size: 21px;
  font-weight: bold;
  opacity: 0.8;
  margin: 0 20px 0 12px;
  font-family: lato;
}
.title {
  flex-grow: 0.06;
  text-align: center;
  font-size: 16px !important;
  padding: 10px 0;
  font-family: lato;
}
.hidetitle {
  flex-grow: 0.06;
  text-align: center;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px 0;
  font-family: lato;
}
.showToolbar {
  margin: 0px 0px 0px 30px !important;
}
.logoImage {
  cursor: pointer;
  width: 180px;
  margin-right: 20px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .showToolbar {
    display: block !important;
    margin: 0px !important;
  }
  .title {
    flex-grow: 0.06;
    text-align: center;
    font-size: 10px !important;
    padding: 10px 0px 10px 0px;
    font-family: lato;
  }
  .a {
    margin-top: 17px !important;
  }
  .a:hover {
    background: #46495c;
  }
  .b {
    margin-top: -29px !important;
    padding-bottom: 25px !important;
  }
  .b:hover {
    background: #46495c;
  }
  .menuButton {
    margin: 0 20px 0 40px !important;
  }
  .hidetitle {
    display: none;
  }

  .logowithicondiv {
    display: flex;
  }
  .navIconbox {
    margin: 10px 20px 0 12px;
    display: inherit;
    cursor: pointer;
  }
  .logoImage {
    margin: 11px 0px 0px 18%;
    width: 125px;
  }
  .navicon {
    width: 30px;
    height: 30px;
  }
}
/* for mbile */

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .headerRoot {
    background: #0e0f15 !important;
  }
}
