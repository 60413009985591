.MuiDialog-paperWidthSm {
  color: white !important;
  background: #3f4d98 !important;
  border-radius: 18px !important;
  box-shadow: 0px 1px 0px 13px rgb(0 0 0 / 12%) !important;
}
.MuiDialog-paper {
  overflow-y: inherit !important;
}
.closebar {
  text-align: right;
  background: #7c7e83;
  border-top-left-radius: 30px !important;
}
.closeimg {
  width: 85%;
}
.closeButton {
  background: radial-gradient(
    97.47% 66.83% at 50% 50%,
    #6677b5 0%,
    #6677b5 100%
  ) !important;
  color: white !important;
  position: relative;
  bottom: 20px;
  height: 44px;
  width: 8%;
  border-radius: 99%;
  left: 95%;
}
.modalcontent {
  position: relative;
  bottom: 30px;
}
.dialogHeading {
  text-align: center !important;
  font-size: 20px;
  font-weight: 900;
}
.dialogcheckboxtext {
  color: #9198a5;
  margin: 12px 0 10px 0px;
  font-size: 14px;
}
.dialoginputGrid {
  margin: 18px 0 0 0 !important;
  text-align: center !important;
}
.dialoginputField {
  border: none;
  background: #242635;
  color: #9198a5;
  font-size: 15px;
  border-radius: 10px;
  padding: 0 0 0 20px;
  width: 100%;
  height: 6vh;
  margin: 2px;
}
.dialoginputField:focus {
  outline: none;
  border: none;
  border-radius: 10px;
}
::placeholder {
  color: #545454;
  opacity: 0.8;
}
.dialogAction {
  text-align: center !important;
  color: white !important;
  position: relative !important;
  bottom: 20px !important;
}
.modalButon {
  cursor: pointer;
  font-weight: 900;
  border: none;
  border-radius: 100px;
  background: linear-gradient(180deg, #ffffff -24.17%, #bac0d6 100%);
  padding: 13px 30px;
  margin: 20px;
}
.modalButon:hover {
  background: linear-gradient(1deg, #ffffff -24.17%, #bac0d6 100%);
}
.popupcheckbox {
  display: flex !important;
  flex-wrap: nowrap !important;
}
.checkboxpop {
  margin-bottom: 25px !important;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
  .closeButton {
    position: relative;
    bottom: 15px;
    height: 40px;
    width: 12%;
    border-radius: 99%;
    left: 92%;
  }
  .closeimg {
    width: 136%;
  }
  .dialogcheckboxtext {
    margin: 19px 0 11px 0px;
    font-size: 12px;
    line-height: 1.5;
  }
  .checkboxpop {
    margin-bottom: 40px !important;
  }
  .dialoginputField {
    height: 5vh;
  }
}
