/* Importing all CSS files */
@import url("./header.css");
@import url("./Body.css");
@import url("./userform.css");
@import url("./Question.css");
@import url("./result.css");
@import url("./dialog.css");
@import url("./login.css");
@import url("./footer.css");
@import url("./cgu.css");
@import url("./CircleTimer.css");
@import url("./QuestionsTimer.css");
@import url("./CheckResult.css");

body {
  font-family: "lato", sans-serif !important;
  overflow-x: hidden;
  background: radial-gradient(ellipse at top, #3334f3, transparent),
    radial-gradient(ellipse at bottom, #000000, black) !important;
}
* {
  font-family: "lato", sans-serif !important;
  font-weight: inherit !important;
  font-style: normal !important;
}
